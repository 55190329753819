// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-ketentuan-js": () => import("./../src/pages/ketentuan.js" /* webpackChunkName: "component---src-pages-ketentuan-js" */),
  "component---src-pages-kirim-js": () => import("./../src/pages/kirim.js" /* webpackChunkName: "component---src-pages-kirim-js" */),
  "component---src-pages-kontak-js": () => import("./../src/pages/kontak.js" /* webpackChunkName: "component---src-pages-kontak-js" */),
  "component---src-pages-laporkan-js": () => import("./../src/pages/laporkan.js" /* webpackChunkName: "component---src-pages-laporkan-js" */),
  "component---src-pages-media-js": () => import("./../src/pages/media.js" /* webpackChunkName: "component---src-pages-media-js" */),
  "component---src-pages-press-js": () => import("./../src/pages/press.js" /* webpackChunkName: "component---src-pages-press-js" */),
  "component---src-pages-privasi-js": () => import("./../src/pages/privasi.js" /* webpackChunkName: "component---src-pages-privasi-js" */),
  "component---src-pages-received-js": () => import("./../src/pages/received.js" /* webpackChunkName: "component---src-pages-received-js" */),
  "component---src-pages-relawan-js": () => import("./../src/pages/relawan.js" /* webpackChunkName: "component---src-pages-relawan-js" */),
  "component---src-pages-tentang-js": () => import("./../src/pages/tentang.js" /* webpackChunkName: "component---src-pages-tentang-js" */),
  "component---src-pages-tv-js": () => import("./../src/pages/tv.js" /* webpackChunkName: "component---src-pages-tv-js" */)
}

