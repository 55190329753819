import React, { useContext } from "react";
import { Link } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";

import GlobalContext from "../../context/GlobalContext";
import Logo from "../Logo";

const Footer = () => {
  const gContext = useContext(GlobalContext);

  const linkClassName =
    gContext.footer.theme === "dark"
      ? "gr-text-color gr-hover-text-green"
      : "gr-text-color gr-hover-text-blue";

  const iconClassName =
    gContext.footer.theme === "dark"
      ? "text-storm gr-hover-text-green"
      : "gr-text-color gr-hover-text-blue";

  const copyClassName =
    gContext.footer.theme === "dark"
      ? "gr-text-color-opacity"
      : "gr-text-color";    

  return (
    <>
      <div
        className={`footer-section pt-15 pt-lg-25 ${
          gContext.footer.theme === "dark"
            ? "dark-mode-texts bg-blackish-blue"
            : ""
        }`}
      >
        <Container>
          <Row className="justify-content-center">
            <Col lg="3" md="4">
              <Logo
                white={gContext.footer.theme === "dark"}
                className="footer-logo mb-11 text-center text-lg-left"
              />
              <p className="gr-text-11 text-center text-lg-left">
              Semua konten dalam situs web ini menggunakan lisensi publik Creative Commons, <a href="https://creativecommons.org/licenses/by-nc/2.0/">CC-BY-NC</a>. Dilarang menggunakan materi di situs web ini untuk kepentingan komersial.
              </p>
            </Col>
            <Col lg="8" md="8" className="offset-lg-1">
              <Row>
                <Col xs="8" lg="4">
                  <div className="single-footer mb-13 mb-lg-9">
                    <p className="footer-title gr-text-11 mb-7">Internal</p>
                    <ul className="footer-list list-unstyled gr-text-9">
                      <li className="py-2">
                        <Link to="/tentang" className={linkClassName}>
                          Tentang Kami
                        </Link>
                      </li>
                      <li className="py-2">
                        <Link to="/kirim" className={linkClassName}>
                          Kirim Tulisan
                        </Link>
                      </li>
                      <li className="py-2">
                        <Link to="/relawan" className={linkClassName}>
                          Pendaftaran Relawan
                        </Link>
                      </li>
                      <li className="py-2">
                        <Link to="/kontak" className={linkClassName}>
                          Kontak
                        </Link>
                      </li>
                    </ul>
                  </div>
                </Col>
                <Col xs="8" lg="4">
                  <div className="single-footer mb-13 mb-lg-9">
                    <p className="footer-title gr-text-11 mb-7">Projects</p>
                    <ul className="footer-list list-unstyled gr-text-9">
                      <li className="py-2">
                        <a href="https://kawalcorona.com" target="_blank" className={linkClassName}>
                          Kawal Corona
                        </a>
                      </li>
                      <li className="py-2">
                        <a href="https://periksadata.com" target="_blank" className={linkClassName}>
                          Periksa Data
                        </a>
                      </li>
                      <li className="py-2">
                        <a href="https://tools.hack.co.id" target="_blank" className={linkClassName}>
                          Tools
                        </a>
                      </li>
                      <li className="py-2">
                        <Link to="/laporkan" className={linkClassName}>
                          Laporkan Penipuan
                        </Link>
                      </li>
                    </ul>
                  </div>
                </Col>
                <Col xs="8" lg="4">
                  <div className="single-footer mb-13 mb-lg-9">
                    <p className="footer-title gr-text-11 mb-7">Legal</p>
                    <ul className="footer-list list-unstyled gr-text-9">
                      <li className="py-2">
                        <Link to="/media" className={linkClassName}>
                          Media Kit
                        </Link>
                      </li>
                      <li className="py-2">
                        <Link to="/privasi" className={linkClassName}>
                          Kebijakan Privasi
                        </Link>
                      </li>
                      <li className="py-2">
                        <Link to="/ketentuan" className={linkClassName}>
                          Syarat 	&amp; Ketentuan
                        </Link>
                      </li>
                    </ul>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <div className="copyright-area border-top py-9">
            <Row className="align-items-center">
              <Col lg="6">
                <p
                  className={`copyright-text gr-text-11 mb-6 mb-lg-0 text-center text-lg-left ${copyClassName}`}
                >
                    Built with 
                  <i
                    className={`icon icon-heart-2-2 ${
                      gContext.footer.theme === "dark"
                        ? "text-sky-blue"
                        : "text-green"
                    } align-middle ml-2`}
                  ></i> using GatsbyJS
                </p>
              </Col>
              <Col lg="6" className="text-center text-lg-right">
                <ul className="social-icons list-unstyled mb-0">
                  <li className="ml-7">
                    <a href="https://twitter.com/EthicalHackID" target="_blank" className={iconClassName}>
                      <i className="icon icon-logo-twitter"></i>
                    </a>
                  </li>
                  <li className="ml-7">
                    <a href="https://facebook.com/ethicalhack.id" target="_blank" className={iconClassName}>
                      <i className="icon icon-logo-facebook"></i>
                    </a>
                  </li>
                  <li className="ml-7">
                    <a href="https://instagram.com/ethicalhack.id" target="_blank" className={iconClassName}>
                      <i className="icon icon-logo-instagram"></i>
                    </a>
                  </li>
                  <li className="ml-7">
                    <a href="https://linkedin.com/company/ethicalhack" target="_blank" className={iconClassName}>
                      <i className="icon icon-logo-linkedin"></i>
                    </a>
                  </li>
                </ul>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Footer;
