import React from "react";
import { Link } from "gatsby";

import logodark from "../../assets/image/logodark.png";
import logowhite from "../../assets/image/logowhite.png";

const Logo = ({ white, height, className = "", ...rest }) => {
  return (
    <Link to="/" className={`d-block ${className}`} {...rest}>
      {white ? (
        <img src={logowhite} alt="" width="172" height="45"/>
      ) : (
        <img src={logodark} alt="" width="172" height="45"/>
      )}
    </Link>
  );
};

export default Logo;
