export const menuItems = [
  {
    name: "tentang",
    label: "Tentang",
  },
  {
    name: "tv",
    label: "TV",
  },
  {
    name: "blog",
    label: "Blog",
  },
  {
    name: "press",
    label: "Liputan Media",
  },
  {
    name: "kontak",
    label: "Kontak",
  },
];
